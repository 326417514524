import React, { useState, useEffect } from 'react'

export default function Header({ background }) {
    const [showMenu, setShowMenu] = useState(false)



    
    return (
        <header>
            <nav>
                {/* <!-- Header Start --> */}
                <div style={{ background: background ? background : "" }} className="pt-2  pb-1 header-area header-transparent">
                    <div className="main-header ">
                        <div className="header-bottom  header-sticky">
                            <div className="container-fluid">
                                <div className="row align-items-center">
                                    {/* <!-- Logo --> */}
                                    <div className="col-xl-2 col-lg-2">
                                        <div className="logo">
                                            <img src="assets/img/logo/Resizelogo.png" style={{ height: 70, width: 250 }} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-xl-10 col-lg-10">
                                        <div className="menu-wrapper d-flex align-items-center justify-content-end">
                                            {/* <!-- Main-menu --> */}
                                            <div className="main-menu d-none d-lg-block">
                                                <nav>
                                                    <ul id="navigation">
                                                        <li><a href="/">Home </a></li>
                                                        <li><a href="pricing">Pricing </a></li>
                                                        <li><a href="career">Career</a></li>
                                                        <li><a href="faq">FAQ</a></li>

                                                        <li><a href="aboutus">About Us</a></li>
                                                        {/* <!-- Button --> */}
                                                        <li className="button-header margin-left "><a href="https://admin.peaktimecourier.com/register" target={"_blank"} className="R_btn btn">Register</a></li>
                                                        <li className="button-header"><a href="https://admin.peaktimecourier.com/login" target={"_blank"} className="btn3">Sign In</a></li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- Mobile Menu --> */}
                                </div>
                                <div className="col-12">
                                    <div className="mobile_menu d-block d-lg-none">
                                        <div className="slicknav_menu">
                                            <a
                                                onClick={() => setShowMenu(!showMenu)}
                                                aria-haspopup={false} role="button" tabIndex={0}
                                                className="slicknav_btn slicknav_collapsed"   >
                                                <span className="slicknav_menutxt">MENU</span>
                                                <span className="slicknav_icon">
                                                    <span className="slicknav_icon-bar"></span>
                                                    <span className="slicknav_icon-bar"></span>
                                                    <span className="slicknav_icon-bar"></span>
                                                </span>
                                            </a>
                                            <ul className={`slicknav_nav slicknav_hidden ${!showMenu ? "d-none" : ""}`} aria-hidden={false} role="menu" >
                                                <li><a href="/" role={'menuitem'} tabIndex={0} >Home</a></li>
                                                <li><a href="pricing" role={'menuitem'} tabIndex={0} >Pricing</a></li>
                                                <li><a href="career" role={'menuitem'} tabIndex={0} >Career</a></li>
                                                <li><a href="faq" role={'menuitem'} tabIndex={0} >FAQ</a></li>
                                                <li><a href="aboutus" role={'menuitem'} tabIndex={0} >About Us</a></li>

                                                <li className="button-header margin-left "><a id="Register_btn" href="https://admin.peaktimecourier.com/register" tabIndex={0} className="btn" role="menuitem" >Register</a></li>
                                                <li className="button-header"><a href="https://admin.peaktimecourier.com/login" className="btn3" tabIndex={0} role="menuitem" >Sign In</a></li>
                                            </ul></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            {/* <!-- Header End --> */}
        </header>
    )
}
