import { Collapse, Timeline } from 'antd';
import React, { useEffect } from 'react';
const { Panel } = Collapse;
const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

export default function TrackingCard({ orderDetails }) {
    const onChange = (key) => {
        console.log(key);
    };

    // useEffect(() => {
    //     console.log("This is orderDetails==============", orderDetails)
    // }, [orderDetails])

    return (
        <div class="js--tracking-result--container c-tracking-result--container is-visible">


            <div class="c-tracking-result--section text-white w-100">
                <div class="c-tracking-result--code">Tracking Code: {orderDetails?.order_id}</div>
                <h4 class="text-white">Status: <strong>{orderDetails?.order_status}</strong></h4>
                <div class="">This shipment is handled by: <strong>Peak Time Courier</strong></div>
            </div>



            <div className='w-100 pt-5 pb-10'>
                <Collapse defaultActiveKey={['1']} onChange={onChange}>
                    <Panel header="Order Date" key="1">
                        <div class="c-tracking-result--delivery ">
                            <div class=" ">Order Date</div>
                            <h2 style={{ color: "black" }}>{orderDetails?.order_date}</h2>

                        </div>


                    </Panel>
                    <Panel header="More Shipment Details" key="2">
                        <div className='view-staffing-table'>
                            <div className='fw-bold'>Pick Address</div>
                            <div className='fw-bold'>{orderDetails?.pick_address}</div>
                        </div>
                        <div className='view-staffing-table'>
                            <div className='fw-bold'>Drop Of Address</div>
                            <div className='fw-bold'>{orderDetails?.drop_of_address}</div>
                        </div>
                        <div className='view-staffing-table'>
                            <div className='fw-bold'>Package Type</div>
                            <div className='fw-bold'>{orderDetails?.package_type}</div>
                        </div>
                        {/* <div className='view-staffing-table'>
                            <div className='fw-bold'>Quantity</div>
                            <div className='fw-bold'>{orderDetails?.quantity}</div>
                        </div> */}
                        <div className='view-staffing-table'>
                            <div className='fw-bold'>Weight</div>
                            <div className='fw-bold'>{orderDetails?.weight}</div>
                        </div>
                        <div className='view-staffing-table'>
                            <div className='fw-bold'>Service Type</div>
                            <div className='fw-bold'>{orderDetails?.service_type}</div>
                        </div>

                        <div className='view-staffing-table'>
                            <div className='fw-bold'>Shipper Contact</div>
                            <div className='fw-bold'>{orderDetails?.shipper_contact}</div>
                        </div>
                        {orderDetails?.quantity && <div className='view-staffing-table'>
                            <div className='fw-bold'>Quantity</div>
                            <div className='fw-bold'>{orderDetails?.quantity}</div>
                        </div>}
                        <div className='view-staffing-table'>
                            <div className='fw-bold'>Receiver</div>
                            <div className='fw-bold'>{orderDetails?.receiver}</div>
                        </div>
                        <div className='view-staffing-table'>
                            <div className='fw-bold'>Receiver Phone</div>
                            <div className='fw-bold'>{orderDetails?.receiver_phone}</div>
                        </div>


                    </Panel>
                    <Panel header="All Shipment Update" key="3">
                        <Timeline>
                            {orderDetails?.shipment_detail?.length > 0 && orderDetails?.shipment_detail?.map((val, index) => {
                                return (

                                    <Timeline.Item color={'#F75601'} key={index}>{val}</Timeline.Item>
                                )
                            })}
                        </Timeline>
                    </Panel>
                </Collapse>
            </div>





        </div>
    )
}
