import { ImageConstant } from "../images";

export const AppConstants = {
  businessPoints: [
    "Monthly Invoice payment",
    "Exclusive offers",
    "Free delivery for limited orders",
    "Special membership card",
    "Premium customer service",
  ],
  nonBusinessPoints: [
    "Special discounts via friend referral code",
    "Free account",
    "Secure payment method",
    "24/7 customer support",
    "Cheap rates",
  ],
  FAQ: [
    // {
    //   question: "How to report the Driver?",
    //   answer:
    //     "For this purpose please contact at our telephone number:  +1 (647) 581-8064 or email us with your order number at info@peaktimecourier.com",
    // },
    {
      question: "How to register as a driver or dispatcher?",
      answer: `For registering as a driver or dispatcher please email us at career@peaktimecourier.com while mentioning the subject line as applying for driver/dispatcher in Peaktime courier 
            after that, we will give you a form to fill if everything goes right we will call you for registration`,
    },
    {
      question: "Need Any Assistance?",
      answer:
        "For any assistance please email us at info@peaktimecourier.com or call of helpline number: +1 (647) 581-8064",
    },
    {
      question: "How far can we deliver?",
      answer:
        "We are GTA based company and we also deliver to surrounding area and long distance.",
    },
    {
      question: "What is Base Fare? ",
      answer: "the minimum base fare is $7",
    },
    {
      question:
        "What is the maximum weight we can deliver for non-business customers?",
      answer: "The maximum weight limit is 120 Pound",
    },
    {
      question:
        "Can I change or cancel my delivery order after it has been placed?",
      answer:
        "In order to change the delivery or cancel an order please contact info@peaktimecourier.com",
    },
    {
      question: "What happens if my delivery is lost or damaged during transit",
      answer:
        "We will provide maximum damage or lost maximum $150 depending on the value of order",
    },
    // {
    //   question: "What is your return policy for items delivered to me?",
    //   answer:
    //     "Please visit our terms & conditions page for complete information",
    // },
  ],
  chooseOptions: [
    {
      Heading: "Affordable Rates",
      Description:
        "We offer the most affordable and competitive rates for courier services in the entire GTA and surrounding areas and long distances . ",
      image: ImageConstant.affordableRates,
    },
    {
      Heading: "Real-Time Tracking",
      Description:
        "We provide easy-to-navigate and accurate real-time order tracking",
      image: ImageConstant.realTime,
    },
    {
      Heading: "24/7 Ordering",
      Description:
        "Our easy-to-use website and mobile app let you place orders at any time of the day. ",
      image: ImageConstant.ordering,
    },
    {
      Heading: "Instant Quote",
      Description:
        "Get an instant and accurate price estimation on your selected destination.",
      image: ImageConstant.instantQuote,
    },
    {
      Heading: "Free Account",
      Description:
        "Take advantage of all of our services with an easy-to-set-up accessible business account. ",
      image: ImageConstant.freeAccount,
    },
    {
      Heading: "Promotions",
      Description:
        "Get exclusive offers and gifts via special promotion offers.",
      image: ImageConstant.promotion,
    },
  ],
  Prices: [
    {
      service_id: 1,
      cut_off_time: "3:30 PM",
      service_type: "90 mins",
      price: "17",
    },
    {
      service_id: 2,
      cut_off_time: "3:30 PM",
      service_type: "2 hours",
      price: "13",
    },
    {
      service_id: 3,
      cut_off_time: "1:00 PM",
      service_type: "4 hours",
      price: "9",
    },
    {
      service_id: 4,
      cut_off_time: "11:30 AM",
      service_type: "5.5 hours (Same Day)",
      price: "8",
    },
    {
      service_id: 5,
      cut_off_time: "5:00 PM",
      service_type: "5-8 hours (after hour)",
      price: "20",
    },
    {
      service_id: 6,
      cut_off_time: "before 10:30 AM",
      service_type: "Next Day before 10:30 AM",
      price: "16",
    },
    {
      service_id: 7,
      cut_off_time: "before 11:30 AM",
      service_type: "Next Day before 11:30 AM",
      price: "10",
    },
    {
      service_id: 8,
      cut_off_time: "Midnight",
      service_type: "Next Day befor 2PM",
      price: "9",
    },
    {
      service_id: 9,
      cut_off_time: "Midnight",
      service_type: "Next Day before 5PM",
      price: "7",
    },
    {
      service_id: 10,
      cut_off_time: null,
      service_type: "Future Date Schedule 1PM to 5PM",
      price: "8",
    },
    {
      service_id: 11,
      cut_off_time: null,
      service_type: "Future Date Schedule 10AM to 2PM",
      price: "8",
    },
  ],
};

export const APIConstants = {
  apiUrl: "https://admin.peaktimecourier.com/api/",
  token: {
    Authorization:
      "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYjMzZTI0YTViNzhlOWYyY2ZlZjEwN2Y5ODg1ZWQ0ZDI3M2NjMDVjYTEzNzcyOWIxNWYwOGJlZGFhZjE1MDBiYjNiMDkxYmRjMmQyN2JmYjAiLCJpYXQiOjE3MjM1Nzc5MDMuMjQ2MTIsIm5iZiI6MTcyMzU3NzkwMy4yNDYxMjYsImV4cCI6MTc1NTExMzkwMy4yNDE4OTUsInN1YiI6IjQiLCJzY29wZXMiOltdfQ.kiNRufvbRLwYW9YutIoUnOyHFi-cFTR4amod0sFAeqJVlx1Q3c5y729GYsRCgy-quxw4veDQBuwbWJvUbVhMrUwxj4p9sCsj_ZHn_LiEtGRUK4OCwRLCR9T43cQ4_P0XeznyGK30FwEFYmZ3cZPe8jwRoQNNSaZiQp0cZMH5fILLiDxjqdoMpIUbsLjLaAOS2tdq6cXAVhtDDhseKNDjcOuleMq2iLQ6S3DbCyhzwMOUia0Q9pRWu685DyfmCTcZFTYLcq6bhVRo5rNsm2CFu1dV5yPipr3tTvcMy4M0Ird03V2ElduqbSNGBJ5GsPBnopJ-iPjOK0h3ErldZkpTRq-mVHHG0omH9kZcW4fErchnqatJtRqvyCN8aT1qyB-7XMf1BR5G9rDsvtfhbOCE3zcn_48fPo8ivKJ7a5_aZWq9VjM2U_jxaE2V0NXkkolyn7DUhoJAyFE5myaNeiaF-rv_3cFD3ACqkbJANOSSXRBX_RUx9LYk4SwJmKuQuV3kEGUVjnmfMaje-Rjg2zyxNcKhFKVGJvK2w-pnhTGKw7ays-nnk4XKtpWZaX8Ch7a6ujo1vn0wY5laxEZ3Q091LPMPR7-Zbb4Q1bd95G_oeJYCyfuZIuwTydmCafiWYBx8pOhBd1UN2FLSvstA2iXnol0QVQuHjXocK-Acg23H3qg",
  },
};

