import React, { useState } from 'react'

import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios'

import Footer from '../components/Footer'
import Header from '../components/Header'
import { ImageConstant } from '../images/index'
import { APIConstants } from '../Helpers/Constants';


export default function AboutUs() {
    const [formData, setFormData] = useState({ name: "", email: "", phone: "", subject: "", comment: "" })
    const postMessageRequest = () => {
        const messageRequestData = new FormData();
        const config = { headers: APIConstants.token }
        var re = /\S+@\S+\.\S+/;
        messageRequestData.append("name", formData.name)
        messageRequestData.append("email", formData.email)
        messageRequestData.append("phone", formData.phone)
        messageRequestData.append("subject", formData.subject)
        messageRequestData.append("comment", formData.comment)
        if (re.test(formData.email)) {
            axios.post(`${APIConstants.apiUrl}contact_us`, messageRequestData, config).then((res) => {
                console.log('this is api Request', res)
                toast.success("Success")
                setFormData({ name: "", email: "", phone: "", subject: "", comment: "" })
            }).catch(err => {
                toast.error("Failed")
                console.log('this is api About us error', err)
            })
        } else {
            toast.error('please enter a valid email')
        }
    }

    return (
        <div>
            <Header background={'#06123C'} />
            <div className="page-wrapper">

                {/* <!--Contact Details Start--> */}
                <section className="contact-details"style={{ padding: 220 }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-7 col-lg-6">
                                <div className="contact-details__img">
                                    <img src={ImageConstant.aboutus} alt="" />
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-6">
                                <div className="contact-details__right">
                                    <div className="section-title text-left">
                                        <div className="section-title__tagline-box">
                                            <div className="section-title-shape">
                                                <img src="assets/images/shapes/section-title-shape.png" alt="" />
                                            </div>
                                        </div>
                                        <h2 style={{ color: "white" }} className="section-title__title">Get in touch with us</h2>
                                    </div>
                                    <p className="contact-details__text">If you have any questions or comments, we'd love to hear from you! Please don't hesitate to get in touch with us via phone, email, or through our website's contact form. Our friendly team is always here to help and will get back to you as soon as possible.</p>
                                    <ul className="list-unstyled contact-details__info">
                                        <li>
                                            <div className="icon">
                                                <span className="icon-telephone"><img style={{ height: 50, width: 50 }} src={ImageConstant.mobile} /></span>
                                            </div>
                                            <div className="text">
                                                <p>Have any question?</p>
                                                <a href="tel:16475818064"><span>Free</span> +1 (647) 581-8064</a>
                                            </div>
                                        </li>
                                        <li>
                                            <div className=" icon">
                                                <span className="icon-email"><img style={{ height: 50, width: 50 }} src={ImageConstant.emailPic} /></span>
                                            </div>
                                            <div className="text">
                                                <p>Write email</p>
                                                <a href="mailto:info@peaktimecourier.com">info@peaktimecourier.com</a>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <span className="icon-pin"><img style={{ height: 50, width: 50 }} src={ImageConstant.map} /></span>
                                            </div>
                                            <div className="text">
                                                <p>Our Location</p>
                                                <span>Toronto,Ontario.Canada</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!--Contact Details End--> */}

                {/* <!--Contact Page Start--> */}
                <section className="contact-page">
                    <div className="container">
                        <div className="section-title text-center">
                            <div className="section-title__tagline-box">
                                <span className="section-title__tagline">Send us email</span>
                                <div className="section-title-shape">
                                    <img src="assets/images/shapes/section-title-shape.png" alt="" />
                                </div>
                            </div>
                            <h2 style={{ color: "white" }} className="section-title__title">Feel free to write</h2>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="contact-page__form">
                                    <div className="comment-one__form contact-div-validated" novalidate="novalidate">
                                        <div className="row">
                                            <div className="col-xl-6">
                                                <div className="comment-form__input-box">
                                                    <input value={formData.name} style={{ borderRadius: 8 }} onChange={(e) => { setFormData({ ...formData, name: e.target.value }) }} type="text" placeholder="Your name" name="name" />
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="comment-form__input-box">
                                                    <input value={formData.email} style={{ borderRadius: 8 }} onChange={(e) => { setFormData({ ...formData, email: e.target.value }) }} type="email" placeholder="Email address" name="email" />
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="comment-form__input-box">
                                                    <input value={formData.phone} style={{ borderRadius: 8 }} onChange={(e) => { setFormData({ ...formData, phone: e.target.value }) }} type="number" placeholder="Phone number" name="Phone" />
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="comment-form__input-box">
                                                    <input value={formData.subject} style={{ borderRadius: 8 }} onChange={(e) => { setFormData({ ...formData, subject: e.target.value }) }} type="text" placeholder="Subject" name="Subject" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="comment-form__input-box text-message-box">
                                                    <textarea value={formData.comment} style={{ borderRadius: 8 }} onChange={(e) => { setFormData({ ...formData, comment: e.target.value }) }} name="message" placeholder="Write comment"></textarea>
                                                </div>
                                                <div className="comment-form__btn-box">
                                                    <div style={{ display: 'flex', alignItems: "center", alignContent: "center", justifyContent: "center" }}>
                                                        <button disabled={formData.comment == "" || formData.name == "" || formData.email == "" || formData.phone == "" || formData.subject == "" ? true : false} onClick={postMessageRequest} type="submit" className="thm-btn btn">Send a message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!--Contact Page End--> */}

                {/* <!--Google Map Start--> */}
                <section className="google-map-two">
                    <div >
                        <iframe width="100%" height="600" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d184550.96633404918!2d-79.3891193094162!3d43.718677261712436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4cb90d7c63ba5%3A0x323555502ab4c477!2sToronto%2C%20ON%2C%20Canada!5e0!3m2!1sen!2s!4v1698864735695!5m2!1sen!2s"><a href="https://www.maps.ie/distance-area-calculator.html">distance maps</a></iframe>

                    </div>

                </section>
                {/* <!--Google Map End--> */}

                {/* <!--Site Footer Start--> */}

                {/* <!--Site Footer End--> */}


            </div >
            <Footer />
        </div >
    )
}
