
import React, { useState } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import axios from 'axios'
import { APIConstants } from '../Helpers/Constants'
import toast from 'react-hot-toast'
import { ImageConstant } from '../images'

export default function Pricing() {
    const [showLoadingModal, setShowLoadingModal] = useState(false)
    const [jobForm, setJobForm] = useState({
        jobTitle: "Dispatcher",
        name: "",
        email: "",
        resume: null
    });

    const submitJobRequest = () => {
        console.log(jobForm)

        var re = /\S+@\S+\.\S+/;

        const config = { headers: APIConstants.token }


        const jobFormData = new FormData();
        jobFormData.append("name", jobForm.name)
        jobFormData.append("email", jobForm.email)
        jobFormData.append("job_title", jobForm.jobTitle)
        jobFormData.append("resume", jobForm.resume)

        if (re.test(jobForm.email)) {
            try {
                axios.post(`${APIConstants.apiUrl}career`, jobFormData, config)
                    .then(res => {
                        if (res.data == "Success") {
                            toast.success("Successfully Submitted")
                            setJobForm({ ...jobForm, email: "", resume: null, name: "" })
                        }
                    })
                    .catch(err => {
                        console.log('this is err-----------------', err)
                        toast.error('failed')
                    })
            } catch (err) {
                toast.error('failed')
            }
        } else {
            toast.error('please enter a valid email')
        }
    }
    const onFileChangeHandler = (e) => {
        setJobForm({ ...jobForm, resume: e.target.files[0] })
    }
    return (
        <div className="career_page_item">
            <Header background={'#06123C'} />
            <div id="booking" className="section">
                <div className="section-center" style={{ marginTop: 100, marginBottom: 100 }}>
                    <div className="container">
                        <div className="row">
                            <div className="center_item col-md-12 col-md-push-5">
                                <div className="booking-cta">
                                    <h1>Join Our Team</h1>
                                    <p>“If anyone interested to join our employment opportunity please contact <strong> career@peaktimecourier.com</strong> “
                                    </p>
                                    <img src={ImageConstant.careerLeft} style={{ height: 300, width: 600, imageResolution: "from-image", objectFit: "contain" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
