import React from 'react'

export default function PlanCard({ Heading, Image, points }) {
    return (
        <div className="mt-5 col-xl-4 col-lg-4 col-md-6 col-sm-10">
            <div className="single-card text-center mb-30">
                <div className="card-top">
                    <img width={120} height={120} src={Image} alt="" />
                    <h4 style={{color:"black"}}>{Heading}</h4>
                </div>
                <div className="card-mid">

                </div>
                <div className="card-bottom">
                    <ul>
                        {points.length > 0 && points.map((point, index) => (
                            <li key={index}>{point}</li>
                        ))}

                    </ul>
                    <a href="https://admin.peaktimecourier.com/register" className="borders-btn">Get Started</a>
                </div>
            </div>
        </div>
    )
}
