import Business from './business.png'
import Customer from './customer.jpg'
import AffordableRates from './affordable.png'
import FreeAcount from './freeacount.png'
import InstantQuote from './instantQuote.png'
import RealTime from './realTime.png'
import Promotions from './promotion.png'
import Logo from './Resizelogo.png'
import Ordering from './Ordering.png'
import Main from './main.png'
import AboutUs from './aboutus.png'
import EmailPic from './emailicon.png'
import MapIcon from './mapicon.png'
import MobileIcon from './mobileicon.png'
import CareerLeft from './careerLeft.png'
import pricingBelow from './pricingBelow.png'
import footerImage from './footer.png'
import americanExpress from './americanExpress.png'


export const ImageConstant = {
    logo: Logo,
    emailPic: EmailPic,
    map: MapIcon,
    mobile: MobileIcon,
    main: Main,
    aboutus: AboutUs,
    business: Business,
    customer: Customer,
    affordableRates: AffordableRates,
    freeAccount: FreeAcount,
    instantQuote: InstantQuote,
    realTime: RealTime,
    promotion: Promotions,
    ordering: Ordering,
    careerLeft:CareerLeft,
    pricingBelow:pricingBelow,
    footerImage:footerImage,
    americanExpress:americanExpress

}