import React, { useEffect } from 'react'
import { Toaster } from 'react-hot-toast';
import 'antd/dist/antd.css';
import HomePage from './Pages/HomePage';
import Routes from '../src/Routes'
function App() {

  return (
    <React.Fragment>
      <Toaster />
      <Routes />
    </React.Fragment>
  )
}

export default App;
