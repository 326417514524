import React, { useRef, useEffect, useState } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import AutoComplete from 'react-google-autocomplete'
import { APIConstants } from '../Helpers/Constants'
import Loader from '../components/Loader'
import PricingModal from '../components/PricingModal'
import axios from 'axios'
import { ImageConstant } from '../images'

export default function Pricing() {
    const [packageData, setPackageData] = useState({
        packageType: "",
        weight: '',
    })
    const [pickupData, setPickupData] = useState({ lat: "", long: "" })
    const [destinationData, setDestinationData] = useState({ lat: "", long: "" })
    const [allPackages, setAllPackages] = useState([])
    const [distance, setDistance] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [showLoading, setShowLoading] = useState(false)

    function toRad(Value) {
        return Value * Math.PI / 180;
    }

    function calcCrow(lat1, lon1, lat2, lon2) {
        var R = 6371; // km
        var dLat = toRad(lat2 - lat1);
        var dLon = toRad(lon2 - lon1);
        var lat1 = toRad(lat1);
        var lat2 = toRad(lat2);

        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        console.log('this is d', d)
        showDateRequest(d)
        return d;
    }
    useEffect(() => {
        console.log('pickup Data================', pickupData);
        console.log('destination Data================', destinationData);
    }, [packageData, pickupData, destinationData])

    const reqDistance = (e) => {
        e.preventDefault();
        calcCrow(pickupData.lat, pickupData.long, destinationData.lat, destinationData.long)
    }
    const showDateRequest = async (distance) => {

        const config = { headers: APIConstants.token }
        // setShowLoading(true)
        try {

            if (pickupData === '' || destinationData === '') {
                alert('Please enter valid address')
                setShowLoading(false)
                return
            }
            if (packageData.weight === '') {
                alert('Please enter valid weight')
                setShowLoading(false)
                return
            }
            const data = {
                from: pickupData,
                to: destinationData,
                weight: packageData.weight,

            }
            await axios.post(`${APIConstants.apiUrl}price_estimation`, data
                , config)
                .then(res => {
                    if (res.data?.error) {
                        alert(res.data?.error)
                        setShowLoading(false)
                        return
                    }
                    console.log('this is res', res.data)
                    setAllPackages(res.data?.data)
                    setDistance(res.data?.km)
                    setShowLoading(false)
                    setShowModal(true)

                })
                .catch(err => {
                    setShowLoading(false)
                    console.log('this is err------', err)
                })
        } catch (err) {
            setShowLoading(false)

            console.log('this is err', err)
        }
    }
    return (
        <div>
            {showLoading && <Loader />}
            <PricingModal onCancel={() => setShowModal(false)} modalStatus={showModal} modalData={allPackages} distance={distance} />
            <Header background={'#06123C'} />
            <div id="booking" className="section" >
                <div className="section-center">
                    <div className="container mt-30">
                        <div className="row" style={{ marginTop: 180 }}>
                            <div className="col-md-7 col-md-push-5">
                                <div className="booking-cta">
                                    <h1 className="Responsive_p_p" style={{ fontWeight: "bold", color: "white" }}>Get a Quotation!</h1>
                                    <p>Transparent pricing, no surprises.</p>
                                    <img src={ImageConstant.pricingBelow} style={{ height: 300, width: 600, marginTop: 20, imageResolution: "from-image", objectFit: "contain" }} />

                                </div>
                            </div>
                            <div className="col-md-4 col-md-pull-7">
                                <div className="booking-form">
                                    <form>
                                        <div className="form-group">
                                            <span className="form-label">Package Type</span>
                                            <input
                                                value={packageData.packageType}
                                                onChange={(e) => setPackageData({ ...packageData, packageType: e.target.value })}
                                                className="form-control"
                                                type="text"
                                                placeholder="Enter Package short Description" />
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <span className="form-label">Pickup</span>
                                                    <AutoComplete
                                                        id=''
                                                        className='form-control'

                                                        placeholder='Pickup Location'
                                                        apiKey={"AIzaSyAdcqj-mU3yqCvQpHB8p-WvBE-nXwhpboQ"}
                                                        onChange={(e) => {
                                                            setPickupData(e.target.value)
                                                        }}
                                                        options={{
                                                            componentRestrictions: { country: 'ca' }
                                                        }}
                                                        
                                                    /> </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <span className="form-label">Destination</span>
                                                    <AutoComplete
                                                        className='form-control'
                                                        placeholder='Destination Location'
                                                        apiKey={"AIzaSyAdcqj-mU3yqCvQpHB8p-WvBE-nXwhpboQ"}
                                                        onChange={(e) => {
                                                            setDestinationData(e.target.value)
                                                        }}
                                                        options={{
                                                            componentRestrictions: { country: 'ca' }
                                                        }}

                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-sm-12">
                                                <div className="form-group">
                                                    <span className="form-label">Weight (Lbs)</span>
                                                    <input
                                                        type={'number'}
                                                        className={'form-control'}
                                                        value={packageData.weight}
                                                        onChange={(e) => setPackageData({ ...packageData, weight: e.target.value })}
                                                    />
                                                </div>
                                            </div>


                                        </div>
                                        <div style={{ alignSelf: "center", display: "flex", alignContent: "center", alignItems: "center", justifyContent: 'center', }}>
                                            <button onClick={reqDistance} className="btn">Get Estimate  </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )

}