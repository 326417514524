import React, { useState, useEffect } from 'react'
import { Modal, Table } from 'antd'
const columns = [
  {
    title: 'Service Id',
    dataIndex: 'service_id',
  },
  {
    title: 'Time',
    dataIndex: 'cut_off_time',

  },
  {
    title: 'Service Type',
    dataIndex: 'service_type',

  },
  {
    title: 'Price',
    dataIndex: 'price',

  },
  {
    title:'Distance',
    dataIndex:'distance'
  }

];
export default function PricingModal({ modalStatus, onCancel, modalData, distance="" }) {
  const [orderData, setOrderData] = useState([])
  const [updatedData, setUpdatedData] = useState([])
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  const sortOrder = () => {
    var numArray = [...modalData];
    numArray.sort(function (a, b) {
      return a.service_id - b.service_id
    });
    // console.log("This is numarray======================",numArray)
    setOrderData(numArray)

  }
  useEffect(() => {
    if (modalData) {
      setUpdatedData(modalData?.map((item, index) => {
          return {
              ...item,
              price: `$ ${item.price}`, distance: `${distance} km`
          }
      }))
      
      
      sortOrder(modalData)
    }
  }, [modalData])
  console.log(modalData)
  return (
    <Modal width={1000} footer={false} centered visible={modalStatus} onCancel={onCancel}>
      <div className='mt-5'>
        <Table style={{ padding: 0 }} columns={columns} dataSource={updatedData} onChange={onChange} />
      </div>

    </Modal>
  )
}
