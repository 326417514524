import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { AppConstants } from '../Helpers/Constants'

export default function FAQ() {
    return (
        <div>
            <Header />
            <section className="ask-questions1 section-bg1 section-padding30 fix">
                <div className="container">
                    <div   className="row justify-content-center">
                        <div className="col-xl-8 col-lg-9 col-md-10 ">
                            {/* <!-- Section Tittle --> */}
                            <div className="section-tittle text-center mb-90">
                                <h2>Frequently ask questions</h2>
                                <p>Have any questions in mind check our frequently asked questions below  </p>
                            </div>
                        </div>
                    </div>
                    <div   className="row">

                        {AppConstants.FAQ.map((question, index) => (
                            <div  className="col-lg-6 col-md-6">
                                <div key={index} className="single-question d-flex mb-50">
                                    <span> Q.</span>
                                    <div className="pera">
                                        <h2>{question.question}</h2>
                                        <p>{question.answer}</p>
                                    </div>
                                </div>
                            </div>
                        ))}


                    </div>

                </div>
            </section>
            <Footer />
        </div>
    )
}
