import React from 'react'

export default function Privacy() {
    return (


        // New Privacy Policy 

        <div className='container mt-5 mb-5' id=' privacy_text'>
            <div style={{ color: "black" }} >
                <h3> Privacy Policy </h3>
                <p style={{ color: "black", lineHeight: "40px" }} > Please review the following section to better understand our privacy policy and practices. We maintain physical, electronic and procedural safeguards to guard your non-public personal information.
                    We also restrict access to your card data by encrypting that information once it is entered into our operating system. We may install cookies on your computer to enable your quicker access to our operating system. We also collect your usage data including, but not limited to, your name and email address, all pickup and delivery information including company name, address, phone number(s) and billing information. We may also collect your computer’s I.P. address and other data during computer to computer transactions. In all instances, tracking packages related to your account can only be accessed through your account and is not shared to any third party unless you willingly share that information yourself.
                    Any visitors to Peaktimecourier.com may be able to access general information about the company, its policies and procedures, and how to best serve you as either a client or a driver. Any details pertaining to specific existing accounts is not privy to the public through the Peaktimecourier website or any other system except by such user that already has an existing account. Peaktime courier account provides clients with information on tracking package, submitting orders, and viewing order history. </p>
            </div>

            <div>
                <h3> Collecting and Using Your Personal Data Types of Data Collected Personal Data</h3>
                <p style={{ color: "black", lineHeight: "40px" }}>We collect personal information that you voluntarily provide to us when registering at our website, expressing an interest in obtaining information about us or our products and services, information that we collect depends on the context of your interactions with us and the choices you make and the products and features you use. The personal information we collect can include the following:   </p>
                <p style={{ color: "black", lineHeight: "40px" }}> <strong>Credentials. </strong> We collect passwords, password hints, and similar security information used for authentication and account access. </p>
                <p style={{ color: "black", lineHeight: "40px" }} > <strong>Payment Data.  We collect data necessary to process your payment if you make purchases, such as your payment instrument number (such as a credit card number), and the security code associated with your payment instrument. All payment data is stored by Stripe. </strong> </p>
                <p style={{ color: "black", lineHeight: "40px" }}>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to: </p>
                
                    <p style={{ color: "black"}} >1. Email address </p>
                    <p style={{ color: "black"}}>2. First name and last name Phone number </p>
                    <p style={{ color: "black"}} >3. Phone number</p>
                    <p style={{ color: "black"}} >4. Address, State, Province, ZIP/Postal code, City </p>

                
            </div>

            <div>
                <h3> Usage Data </h3>
                <p style={{ color: "black", lineHeight: "40px" }} >Usage Data is collected automatically when using the Service.
                    Usage Data may include information such as Your Device’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit,
                    the time spent on those pages, unique device identifiers and other diagnostic data.  </p>
            </div>

            <div>
                <h3>Tracking Technologies and Cookies</h3>
                <p style={{ color: "black", lineHeight: "40px" }} >We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags,
                    and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:  </p>

            </div>


            <div>
                <h3>Cookies or Browser Cookies. </h3>
                <p style={{ color: "black", lineHeight: "40px" }} > file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service.
                    Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies </p>
            </div>

            <div>
                <h3>Use of Your Personal Data </h3>
                <p style={{ color: "black", lineHeight: "40px" }} >The Company may use Personal Data for the following purposes:</p>
                <p style={{ color: "black", lineHeight: "40px" }} > <strong>-To provide and maintain our Service, </strong> , including to monitor the usage of our Service.</p>
                <p style={{ color: "black", lineHeight: "40px" }} > <strong>-To manage Your Account:</strong>to manage Your registration as a user of the ----  Service. The Personal Data You provide can
                    give You access to different functionalities of the Service that are available to You as a registered user.  </p>
                <p style={{ color: "black", lineHeight: "40px" }} ><strong> -For the performance of a contract:</strong> the development, compliance and undertaking of the purchase contract for
                    the products, items or services You have purchased or of any other contract with Us through the Service. </p>
            </div>

            <div>
                <h3> Retention of Your Personal Data </h3>
                <p style={{ color: "black", lineHeight: "40px" }} > The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
                    The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
            </div>

            <div>
                <h3> Third Party Products and Services:</h3>
                <p style={{ color: "black", lineHeight: "40px" }} >It is possible that Peaktimecourier.com may consist of products and services offered by third parties. When you click on one of these third party services, the only information that carries to the third party is the fact that you came from the Peaktimecourier website. The third party is responsible for monitoring its own information and our
                    company exempts itself from any liability concerning content, policies, and privacy once you leave the Peaktimecourier website. </p>
                <p style={{ color: "black", lineHeight: "40px" }} ><strong>Consent:</strong> We may process your data if you have given us specific consent to use your personal information in a specific purpose. </p>

                <p style={{ color: "black", lineHeight: "40px" }}><strong>Legal Obligations: </strong >We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal
                    process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</p>
            </div>

            <div>
                <h3> Changes to Privacy Policy: </h3>
                <p style={{ color: "black", lineHeight: "40px" }} > If Peaktime courier changes it Privacy Policy, it will notify users by posting a link on
                    the homepage. The link will also detail the date that changes will take place. </p>
            </div>
        </div>
    )
}
