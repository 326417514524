import React from 'react'

export default function Terms() {
    return (
        <div className='container'>
            <div style={{ padding: 20 }} >
                <div>
                    <h3>AGREEMENT TO TERMS</h3>
                    <p style={{ color: "black" }}> Supplemental terms and conditions or documents that may be posted on the Site from time to time are
                        hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Terms of Use at any time and for any reason. We will alert you about any changes by updating the “Last updated” date of these Terms of Use, and you waive any right to receive specific notice of each such change. It is your responsibility to periodically review these Terms of Use to stay informed of updates. You will be subject to, and will be deemed to have been made aware and to have accepted, the changes in any revised Terms of Use by
                        your continued use of the Site after the date such revised Terms of Use are posted. </p>
                </div>
                <div>
                    <h3>USER REGISTRATION </h3>
                    <p style={{ color: "black" }}>You may be required to register with the Site. You agree to keep your password confidential and will be responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a username you select if we determine, in our sole discretion, that such username is inappropriate,
                        obscene, or otherwise objectionable. </p>
                </div>
                <div>
                    <h3>PROHIBITED ACTIVITIES </h3>
                    <ul>
                        <li> 1: Make any unauthorized use of the Site, including collecting usernames and/or email addresses of users
                            by electronic or other means for the purpose of sending unsolicited email, or creating user
                            accounts by automated means or under false pretenses. </li>
                        <li>2: Use the Site to advertise or offer to sell goods and services. </li>
                        <li>3:  Circumvent, disable, or otherwise interfere with security-related features of the Site, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Site and/or the Content contained therein. </li>
                        <li>4: Engage in unauthorized framing of or linking to the Site.</li>
                        <li>5: Make improper use of our support services or submit false reports of abuse or misconduct.</li>
                        <li>6: Attempt to impersonate another user or person or use the username of another user.</li>
                        <li>7: Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Site to you. </li>
                        <li>8: To create a valid delivery order, client must be 18 years of age or older, </li>
                    </ul>
                </div>
                <div>
                    <h3> Peaktime Courier Terms and Condition </h3>
                    <ul>
                        <li>1: Our Courier guarantees on time shipment of an item, unless circumstances outside of the control ,interrupts such delivery. In such cases where our Courier fails to meet delivery standards, being on time
                            with item in sound condition, then a credit will be applied to client’s next order. All conditions as
                            outlined in these terms must be followed by client for any claim of failure to be on time is made. </li>
                        <li>2: Non Business customer that pays for our delivery services understands that once the delivery is ordered that the amount paid is non-refundable as the service has been dispatched. Method of payment must be agreed upon prior to any driver being dispatched. Any method of payment other than invoice or
                            through online system must be agreed upon by our Courier and client prior to driver being dispatched. </li>
                        <li>3: Business Customer payment method is monthy inovice.This means that we expect to be paid within 30 days by check or bank transfer etc </li>
                        <li>4: maximum weight is up to 150 pounds and the maximum dimensions in inches are 21L x 21w X 16H
                            and if sending multiple packaging/boxes so it  must be 20 pound each maximum. </li>
                        <li>5: Our company has no obligation to provide additional manpower separate from the assigned driver to load or carry item. It is the sole responsibility of the client to ensure that all measures are taken and all
                            resources made available for the loading of the package or item. </li>
                        <li>6; It is up to the customer to retain a copy, image, or scan of any important documents being delivered by us.
                            Liability for each package is limited to $120 depend on the amount of delivery product </li>
                    </ul>
                </div>
                <div>
                    <h3>Illegal material  </h3>
                    <p style={{ color: "black" }}> There are some items that should not be sent by our
                        courier services such as
                        fire arms, Ammunition, Drugs, and any other ilegal items.</p>

                </div>
            </div>
        </div>
    )
}
