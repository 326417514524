import React from 'react'
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import Home from "../Pages/HomePage";
import Career from "../Pages/Career";
import AboutUs from "../Pages/AboutUs";
import Pricing from "../Pages/Pricing";
import FAQ from '../Pages/FAQ';
import Terms from '../Pages/Terms';
import Privacy from '../Pages/Privacy';

export default function index() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="career" element={<Career />} />
                <Route path="aboutus" element={<AboutUs />} />
                <Route path="pricing" element={<Pricing />} />
                <Route path="faq" element={<FAQ />} />
                <Route path="privacy" element={<Privacy />} />
                <Route path="terms" element={<Terms />} />
            </Routes>
        </BrowserRouter>
    )
}

