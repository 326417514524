import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { APIConstants, AppConstants } from "../Helpers/Constants";
import { ImageConstant } from "../images";

export default function Footer() {
  const [email, setEmail] = useState("");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  console.log(screenWidth)
  const subscribeRequest = () => {
    var re = /\S+@\S+\.\S+/;
    if (re.test(email)) {
      const config = { headers: APIConstants.token };
      const subscribeForm = new FormData();
      subscribeForm.append("email", email);

      axios
        .post(`${APIConstants.apiUrl}subscribe`, subscribeForm, config)
        .then((res) => {
          if (res.data) {
            if (res.data[0] == "Validation Error") {
              toast.error("email already subscribed");
            } else if (res.data == "Success") {
              toast.success("Successfully Subscribed");
              setEmail("");
            }
          }
        })
        .catch((err) => {
          toast.error("failed");
        });
    } else {
      toast.error("please enter a valid email");
    }
  };
  return (
    <footer>
      <div
        className="footer-wrappr "
        style={{
          paddingTop: screenWidth < 768 ? 200 : 0,
        }}
        data-background="assets/img/gallery/footer-bg.png"
      >
        <div className="footer-area footer-padding ">
          <div className="container">
            <div className="row d-flex justify-content-between">
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                <div className="single-footer-caption mb-50">
                  {/* <!-- logo --> */}
                  <div className="footer-logo mb-25">
                    <a href="index.html">
                    <img src="assets/img/logo/logo2_footer.png" style={{ height: 70, width: 250 }} alt="" />
                    </a>
                  </div>
                  <div className="footer-tittle mb-50">
                    <p>
                      Subscribe our newsletter to get updates about our services
                    </p> 
                  </div>
                  {/* <!-- Form --> */}
                  <div className="footer-form">
                    <div id="mc_embed_signup">
                      <div
                        className="subscribe_form relative mail_part"
                        noValidate={true}
                      >
                        <input
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          type="email"
                          name="EMAIL"
                          id="newsletter-form-email"
                          placeholder=" Email Address "
                          className="placeholder hide-on-focus"
                        />
                        <div className="form-icon">
                          <button
                            onClick={subscribeRequest}
                            disabled={email == "" ? true : false}
                            style={{ padding: 0 }}
                            className="btn"
                          >
                            Subscribe
                          </button>
                        </div>
                        <div className="mt-10 info"></div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- social --> */}
                  <div className="footer-social mt-50">
                    <a href="https://twitter.com/login" target="blank" >
                    <i class="fa-brands fa-x-twitter"></i>
                    {/* <i class="fa fa-twitter" aria-hidden="true"></i> */}
                    </a>
                    <a href="https://www.facebook.com/login.php/" target="blank">
                      {/* <i className="fab fa-facebook-f"></i> */}
                      <i class="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                    <a href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQF2_Gl3exXLeQAAAY11QFWYagDKf2ynEezDQSb1_iezqxmamSffIOiTssuBHUohmG8y89YoITEIkyeKJXBbOs3a3T-SABFafaBgCn4OIjgf8scsj0SaIzQhf24UDaPK2H-qOpY=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fmuhammad-imran-abbasi-662b71258%3Futm_source%3Dshare%26utm_campaign%3Dshare_via%26utm_content%3Dprofile%26utm_medium%3Dandroid_app" target="blnk" >
                      {/* <i className="fab fa-pinterest-p"></i> */}
                      <i class="fa fa-linkedin-square" aria-hidden="true"></i>
                    </a>
                    <a href="https://www.instagram.com/" target="blank">
                    <i class="fa-brands fa-instagram"></i>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-xl-2 mt-4 col-lg-2 col-md-4 col-sm-5">
                <div className="footer-tittle">
                  <div className="footer-tittle">
                    <h4>Usefull Links</h4>
                    <ul>
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li>
                        <a href="/pricing"> Pricing</a>
                      </li>
                      <li>
                        <a href="/career">Career</a>
                      </li>
                      <li>
                        <a href="/faq">FAQ</a>
                      </li>
                      <li>
                        <a href="/aboutus">About Us</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className=" mt-4 col-xl-2 col-lg-2 col-md-2 col-sm-2">
                <div className="footer-tittle">
                  <h4>Policies</h4>

                  <ul>
                    <li>
                      <a href="/privacy">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="/terms">Terms and Condition</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 mt-4 col-lg-3 col-md-4 col-sm-5">
                <div className="footer-tittle">
                  <h4>Contact Us</h4>
                  <ul>
                    <li>
                      <div className="text">
                        <p>Have any question?</p>
                        <a tyle={{ color: "white" }} href="tel:16475818064">
                          <span>Free</span> +1 (647) 581-8064
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="text">
                        <p>Write email</p>
                        <a tyle={{ color: "white" }} href="mailto:info@peaktimecourier.com">
                          info@peaktimecourier.com
                        </a>
                      </div>
                    </li>

                    <li>
                      <div className="text">
                        <p className="text">Our Location</p>
                        <span style={{ color: "white" }}>
                          992 Albion Road, Unit 383
                        <br></br>
                          Toronto, ON, M9V 1A7
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- footer-bottom area --> */}
        <div className="footer-bottom-area">
          <div className="container d-flex justify-content-center">
            <div className="footer-border">
              <div className="row">
                <div className="col-xl-12">
                  <div
                    className="footer-copy-right text-center row"
                    style={{ justifyContent: "center" }}
                  >
                    <div className="d-flex"> 
                      <img 
                        height={120}
                        src={ImageConstant.americanExpress}
                      />
                 <img 
                        width={"100%"}
                        height={120}
                        className=" ml-2"
                        src={ImageConstant.footerImage}
                      />
                      
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
