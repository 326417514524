import React, { useEffect, useState } from "react";
import { ImageConstant } from "../images";
import Header from "../components/Header";
import Footer from "../components/Footer";
import PlanCard from "../components/PlanCard";
import { APIConstants, AppConstants } from "../Helpers/Constants";
import Loader from "../components/Loader";
import axios from "axios";
import TrackingCard from "../components/TrackingCard";
import TestimonialCarousel from "../components/TestomonialCarousel";

export default function HomePage() {
  const [showLoading, setShowLoading] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState("");
  const [currentOrder, setCurrentOrder] = useState(null);
  const [showOrder, setShowOrder] = useState(false);

  const showDateRequest = () => {
    setCurrentOrder(null);
    const config = { headers: APIConstants.token };
    // setShowLoading(true);
    try {
      axios
        .get(`${APIConstants.apiUrl}track_order/${currentOrderId}`, config)
        .then((res) => {
          setCurrentOrder(res.data);
          setShowLoading(false);

          setShowOrder(true);
        })
        .catch((err) => {
          setShowLoading(false);
          console.log("this is err------", err);
          setShowOrder(true);
        });
    } catch (err) {
      console.log("this is err", err);
    }
  };

  return (
    <div>
      {showLoading && <Loader />}
      {/* <!-- Preloader Start-- > */}
      <Header />
      {/* <!-- Slider Area Start--> */}
      <div className="slider-area slider-bg">
        <div className="slider-active">
          {/* <!-- Single Slider --> */}
          <div className="single-slider d-flex align-items-center slider-height ">
            <div className="container">
              <div className="row mia align-items-center justify-content-between">
                <div className="col-xl-5 col-lg-5 col-md-9 ">
                  <div className="hero__caption">
                    <h1>Right on time</h1>
                    <p>
                      We are providing guaranteed cheaper prices in whole GTA compared to other courier services
                    </p>
                    {/* <!-- Slider btn --> */}
                    <div className="slider-btns">
                      {/* <!-- Hero-btn --> */}
                      <a href="pricing" className="btn radius-btn">
                        Instant quote
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6">
                  <div className="hero__img d-none d-lg-block f-right">
                    <img
                      style={{
                        height: 600,
                        width: 700,
                        marginTop: 70,
                        objectFit: "cover",
                        marginLeft: -90,
                      }}
                      src={ImageConstant.main}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Slider Shape --> */}
        <div className="slider-shape d-none d-lg-block">
          <img
            className="slider-shape1"
            src="assets/img/hero/top-left-shape.png"
            alt=""
          />
        </div>
      </div>
      {/* <!-- Slider Area End -->
                <!-- Domain-search start --> */}
      <div className="domain-search-area section-bg1">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-5">
              <h2>Track your package</h2>
              <p>Type your order ID to get an instant delivery status</p>
            </div>
            <div className="col-xl-8 col-lg-7">
              {/* <!--Hero form --> */}
              <div className="search-box">
                <div className="input-form">
                  <input
                    value={currentOrderId}
                    onChange={(e) => setCurrentOrderId(e.target.value)}
                    type="text"
                    placeholder="Tracking Number"
                  />
                  {/* <!-- icon search --> */}
                  <div className="search-form">
                    <button
                      disabled={currentOrderId == "" ? true : false}
                      onClick={showDateRequest}
                    >
                      <i class="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </div>
                  {/* <!-- icon search --> */}
                  <div className="world-form">
                    {/* <i className="fas fa-globe"></i> */}
                    <i class="fa fa-globe" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
              <div className="pl-5 mt-3">
                {showOrder && (
                  <div className="d-flex">
                    {currentOrder !== null ? (
                      <TrackingCard orderDetails={currentOrder} />
                    ) : (
                      <h4 style={{ color: "white" }}>{`No Order Found`}</h4>
                    )}
                    {/* <h4 style={{color:"white"}}>{`Not Package Found`}</h4> */}
                  </div>
                )}
              </div>
              {/* <!-- Domain List --> */}
              {/* <!-- Domain List  End--> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Domain-search End -->
    <!--? Team --> */}
      <section className="team-area section-padding40 section-bg1">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-12">
              <div className="section-tittle text-center mb-105">
                <h2>why choose us</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {AppConstants.chooseOptions.map((option, index) => (
              <div key={index} className="col-lg-4 col-md-4 col-sm-6">
                <div className="single-cat">
                  <div className="cat-icon">
                    <img src={option.image} alt="" />
                  </div>
                  <div className="cat-cap">
                    <h5>{option.Heading}</h5>
                    <p>{option.Description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* <!--Services End-- >
    < !--? Pricing Card Start-- > */}
      <section className="pricing-card-area fix">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-8">
              <div className="section-tittle text-center mb-5">
                <h2>Choose plan which fit for you</h2>
                <p>Choose a plan which suits you the best you</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-2 col-lg-2 col-md-4 col-sm-10" />
            <PlanCard
              Heading={"Business Customer"}
              points={AppConstants.businessPoints}
              Image={ImageConstant.business}
            />
            <PlanCard
              Heading={"Non-business Customer"}
              points={AppConstants.nonBusinessPoints}
              Image={ImageConstant.customer}
            />
            <div className="col-xl-2 col-lg-2 col-md-4 col-sm-10" />
          </div>
        </div>
      </section>
      {/* <!--Pricing Card End-- > */}
      {/* < !--? About - 1 Area Start-- > */}

      {/* Counter Area  Starts*/}

      {/* Counter Area Ends */}
      {/* <!--About - 1 Area End-- >
    < !--? About - 2 Area Start-- > */}
      {/* <TestimonialCarousel /> */}
      {/* <!--About - 2 Area End-- > */}
      {/* < !--ask questions-- > */}
      <section className="ask-questions1 section-bg1 section-padding30 fix">
        <div className="container faq_class">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-9 col-md-10 ">
              {/* <!-- Section Tittle --> */}
              <div className="section-tittle text-center mb-90">
                <h2>Frequently ask questions</h2>
                <p>
                  {/* Have any questions in mind check our frequently asked questions below */}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {AppConstants.FAQ.slice(0, 4).map((question, index) => (
              <div key={index} className="col-lg-6 col-md-6">
                <div className="single-question d-flex mb-50">
                  <span> Q.</span>
                  <div className="pera">
                    <h2>{question.question}</h2>
                    <p>{question.answer}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="row">
            <div className="col-xl-12 ">
              <div className="more-btn text-center mt-20">
                <a href="faq" className="btn">
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="testimonial-area section-bg1">
        <div className="container">
          <div id="carouselExampleControls" class="row  carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner col-lg-10 col-md-10 col-sm-12">
              <div className="quotes_top">
                <img src="assets/img/icon/quotes-sign.png" alt="" class="quotes_sign" />
              </div>

              <div class="carousel-item active">
              {/* <div className="quotes_top">
                <img src="assets/img/icon/quotes-sign.png" alt="" class="quotes-sign" />
              </div> */}
                <div className="container  testimonial_para_text" >
                  <p className="text_testi">  I wanted to tell you that I love your courier service. I needed a package picked up so I called your customer care and  they scheduled someone to pick up my package. The pick-up was done as quickly as possible and the staff member who took care of me was very polite. This is the best delivery service in the city!</p>
                </div>
                <div className="testimonial_pic">
                  <img className="" style={{ height: 100, width: 100, borderRadius: 50, objectFit: "cover" }} src="assets/img/icon/Testomonial_image1.jpg" alt="..." />
                  <div className="last_text">
                    <p> Mark Turner</p>
                    <p> Logistics Managers</p>
                  </div>
                </div>
              </div>

              <div class="carousel-item">
              {/* <div className="quotes_top">
                <img src="assets/img/icon/quotes-sign.png" alt="" class="quotes-sign" />
              </div> */}
                <div className="container  testimonial_para_text" >
                <p className="text_testi">I have been using Peaktime Courier for the last 12 months, and it was a great decision to switch. The customer service team is very responsive, and they are always able to answer any of my questions. The best part of this company is their courier service--they are always on time and never call in sick. I don't know how they do it!</p>
                </div>
                <div className="testimonial_pic">
                  <img className="" style={{ height: 100, width: 100, borderRadius: 50, objectFit: "cover" }} src="assets/img/icon/testomonial_image2.jpg" alt="..." />
                  <div className="last_text">
                    <p> Joaquim Leal</p>
                    <p> Restaurant Owner</p>
                  </div>
                </div>
              </div>


              <div class="carousel-item">
              {/* <div className="quotes_top">
                <img src="assets/img/icon/quotes-sign.png" alt="" class="quotes-sign" />
              </div> */}
                <div className="container  testimonial_para_text" >
                <p className="text_testi">Peaktime courier service is one of the best in the biz. I'm always pleasantly surprised at how fast my packages arrive, even when I don't need them to. They make sure that everything goes smoothly and are never late for pickups or deliveries. The drivers are also really friendly and helpful, which is a huge plus!</p>
                </div>
                <div className="testimonial_pic">
                  <img className="" style={{ height: 100, width: 100, borderRadius: 50, objectFit: "cover" }} src="assets/img/icon/Testomonial_3.jpeg" alt="..." />
                  <div className="last_text">
                    <p> Liam Sebastian</p>
                    <p>Shop Owner</p>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
  
                <div className="container  testimonial_para_text" >
                <p className="text_testi">I'm a busy guy and sometimes I just don't have time for my shopping. I've been using Peaktime Courier for a couple of years now and they've never let me down. Not once! Whatever I need, they deliver it on the same day and make sure it's in perfect condition. The prices are great too! There's no other courier service that I would recommend to my friends or family.</p>
                </div>
                <div className="testimonial_pic">
                  <img className="" style={{ height: 100, width: 100, borderRadius: 50, objectFit: "cover" }} src="assets/img/icon/image 4.jpg" alt="..." />
                  <div className="last_text">
                    <p> Jason Miller</p>
                    <p>Product Manager</p>
                  </div>
                </div>
              </div>
              <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          </div>
      </section>


      {/* <!--End ask questions-- > */}
      {/* < !--? Testimonial Area Start-- > */}

      <Footer />
    </div>
  );
}
